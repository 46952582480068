(function(){
  const burger = document.getElementById('jsBurger');
  // ない場合はなにもしない
  if( ! burger ) return;

  // グローバルナビゲーションの要素
  const globalNav = document.getElementById('constructGlobalnav');

  const ignoreElements = [
    document.getElementById('constructGlobalnav'),
    document.getElementById('constructGlobalnav'),
  ];

  burger.onclick = (e) => {

    // バーガー要素
    const b = e.target;

    const ariaPressed = b.getAttribute('aria-pressed');

    // burger が押されているかを確認します
    const pressed = ariaPressed === 'true';

    // burger の aria-pressed を反対の状態に変更します
    burger.setAttribute('aria-pressed', !pressed);

    // グローバルメニューの状態を切り替えます
    globalNav.setAttribute('aria-hidden', pressed);

    // バーガー要素が ON 状態
    if( ariaPressed === 'false' ){
      // body 要素の overflow: hidden の切り替え
      document.body.style.overflow = 'hidden';
    };

    // バーガー要素が OFF 状態
    if( ariaPressed === 'true' ){
      // body 要素の overflow: hidden の切り替え
      document.body.style = '';
    };

    // 子要素内の a タグを取得します（アンカーリンクの処理）
    const childLink = globalNav.querySelectorAll('.child-link');
    childLink.forEach( (link) => {
      link.onclick = (d) => {
        d.stopPropagation();
        burger.setAttribute('aria-pressed', 'false');
        globalNav.setAttribute('aria-hidden', 'true');
        document.body.style = '';
      };
    });
  };
}());
