/**
***  指定したプロパティを要素から取得する  */
export const getStyle = (el, styleProp) => {
  let defaultView = ( el.ownerDocument || document ).defaultView;
  styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase();
  return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
};



/**
*** URL解析して、GET クエリ文字列を返す
*** @return {Array} クエリ文字列
*/
export const getQuery = () => {
  // referrer
  const url = window.location.search;

  // ? を取り除くため、1から始める。複数のクエリ文字列に対応するため、&で区切る
  const hash = url.slice(1).split('&');

  let vars = {};
  for ( let i = 0; i < hash.length; i++ ) {
    const array = hash[i].split('='); // keyと値に分割
    vars[array[0]] = array[1]; // 先ほど確保したkeyに、値を代入
  }

  return vars;
};



// slideUp
export const slideUp = (el, duration = 400) => {
  el.style.height = el.offsetHeight + 'px';
  el.offsetHeight;
  el.style.transitionProperty = 'height, margin, padding';
  el.style.transitionDuration = duration + 'ms';
  el.style.transitionTimingFunction = 'ease';
  el.style.overflow = 'hidden';
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  setTimeout(() => {
    el.style.display = 'none';
    el.style.removeProperty('height');
    el.style.removeProperty('padding-top');
    el.style.removeProperty('padding-bottom');
    el.style.removeProperty('margin-top');
    el.style.removeProperty('margin-bottom');
    el.style.removeProperty('overflow');
    el.style.removeProperty('transition-duration');
    el.style.removeProperty('transition-property');
    el.style.removeProperty('transition-timing-function');
  }, duration);
};



// slideDown
export const slideDown = (el, duration = 400) => {
  el.style.removeProperty('display');
  let display = window.getComputedStyle(el).display;
  if (display === 'none') {
    display = 'block';
  }
  el.style.display = display;
  let height = el.offsetHeight;
  el.style.overflow = 'hidden';
  el.style.height = 0;
  el.style.paddingTop = 0;
  el.style.paddingBottom = 0;
  el.style.marginTop = 0;
  el.style.marginBottom = 0;
  el.offsetHeight;
  el.style.transitionProperty = 'height, margin, padding';
  el.style.transitionDuration = duration + 'ms';
  el.style.transitionTimingFunction = 'ease';
  el.style.height = height + 'px';
  el.style.removeProperty('padding-top');
  el.style.removeProperty('padding-bottom');
  el.style.removeProperty('margin-top');
  el.style.removeProperty('margin-bottom');
  setTimeout(() => {
    el.style.removeProperty('height');
    el.style.removeProperty('overflow');
    el.style.removeProperty('transition-duration');
    el.style.removeProperty('transition-property');
    el.style.removeProperty('transition-timing-function');
  }, duration);
};
