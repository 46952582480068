import {getStyle, slideUp, slideDown} from './export';



(function(){

  // グローバルナビゲーションの要素
  const globalNav = document.getElementById('constructGlobalnav');

  // すべてのトリガーを取得する
  const allTrigger = globalNav.querySelectorAll('.globalnav-trigger');

  // アクティブクラス
  const activeClassName = 'active';

  // 幅が lg 以上のビューポートをターゲットとするメディアクエリを作成
  const mediaQuery = window.matchMedia('(min-width: 992px)');

  let isHidden = !mediaQuery.matches;
  globalNav.setAttribute('aria-hidden', isHidden);

  // Window width
  let memoryWindowWidth = window.innerWidth;



  // イベント
  allTrigger.forEach( elm => {
    // クリックイベント
    elm.onclick = (t) => {
      const trigger = t.target;

      // アクティブ判定
      const isActive = trigger.classList.contains(activeClassName);

      // 一度、すべてのメニューを閉じる
      allTrigger.forEach( el => {
        // アクティブクラスをすべて削除
        el.classList.remove(activeClassName);
        // 高さを取得する要素
        const panel = el.nextElementSibling;
        const maxHeightValue = getStyle(panel, 'maxHeight');
        if (maxHeightValue !== '0px') panel.style.maxHeight = null;
      });

      // アクティブ判定によってアクティブクラスを操作する
      isActive ? trigger.classList.remove(activeClassName) : trigger.classList.add(activeClassName);

      // 高さを取得する要素
      const panel = trigger.nextElementSibling;
      const maxHeightValue = getStyle(panel, 'maxHeight');

      if (maxHeightValue !== '0px') {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      };
    };
  });

  // window リサイズでメニューをクリアにする処理
  let resizeTimer;
  window.addEventListener( 'resize', function(){

    if (! resizeTimer) clearTimeout(resizeTimer);

    resizeTimer = setTimeout( function(){

      let isHidden = !mediaQuery.matches;
      globalNav.setAttribute('aria-hidden', isHidden);
      document.getElementById('jsBurger').setAttribute('aria-pressed', 'false');
      document.body.style.overflow = 'auto';

      allTrigger.forEach( function(el){
        // 子メニューを閉じる
        el.classList.remove(activeClassName);
        const panel = el.nextElementSibling;
        panel.style.maxHeight = null;
      });

    }, 200);

    /*
      // グローバルナビゲーションを非表示
      if( document.body.classList.contains(openMenuClass) ){
        slideUp(globalNav);
      }
      // グローバルナビゲーションのスタイルを除去
      globalNav.style.display = '';
      // body のクラスを削除
      document.body.classList.remove(openMenuClass);
    */

  });

}());
